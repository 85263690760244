import styled from 'styled-components'
import Link from './Link'

const PageSectionTextImageSocialSignupLoginButton = styled(Link).attrs({ role: 'button' })`
  align-items: center;
  background-color: ${({ $linkedIn }) => ($linkedIn ? '#0a66c2' : 'var(--color-background-white)')};
  border: 1px solid ${({ $linkedIn }) => ($linkedIn ? '#0a66c2' : 'var(--color-light-outline)')};
  border-radius: var(--border-radius-large);
  color: ${({ $linkedIn }) => ($linkedIn ? 'var(--color-typography-white)' : 'var(--color-typography-dark)')};
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 14px;
  transition: color 0.2s linear;
  white-space: nowrap;

  > svg {
    margin-right: 10px;
  }

  &:hover {
    border-color: ${({ $linkedIn }) => ($linkedIn ? '#074686' : '#CCC')};
    color: ${({ $linkedIn }) => ($linkedIn ? '#DDD' : '#444')};

    > svg {
      fill: ${({ $linkedIn }) => ($linkedIn ? '#DDD' : '#444')} !important;
      transition: fill 0.2s linear;
    }
  }
`

export default PageSectionTextImageSocialSignupLoginButton
