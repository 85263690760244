import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from '../../utils/IntlContext'
import { getBaseDomain } from '../../utils/locales'

const useGoogleSignUpLink = (locale) => {
  const { allContentfulMicrocopy } = useStaticQuery(graphql`
    query UseGoogleSignUpLink {
      allContentfulMicrocopy(filter: { key: { eq: "sign_up_google" } }) {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `)

  const { defaultLocale } = useIntl()

  const node = allContentfulMicrocopy.edges.find((microcopy) => microcopy.node.node_locale === locale).node
  const defaultLocaleNode = allContentfulMicrocopy.edges.find(
    (microcopy) => microcopy.node.node_locale === defaultLocale,
  ).node

  return {
    node_locale: node.node_locale,
    no_follow: true,
    open_tab: false,
    text: {
      value: node.value || defaultLocaleNode.value,
    },
    url: {
      url: `https://auth.${getBaseDomain()}/users/auth/google_oauth2`,
      app_url: false,
    },
  }
}

export default useGoogleSignUpLink
