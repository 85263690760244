import styled from 'styled-components'
import LinkedInIcon from '../assets/svg/linkedin-square.svg'
import GoogleIcon from '../assets/svg/signup-google.svg'
import useGoogleSignUpLink from '../hooks/queries/useGoogleSignUpLink'
import useLinkedInSignUpLink from '../hooks/queries/useLinkedInSignUpLink'
import useSignUpLink from '../hooks/queries/useSignUpLink'
import { useIntl } from '../utils/IntlContext'
import media from '../utils/media'
import Link from './Link'
import PageSectionTextImageSocialSignupLoginButton from './PageSectionTextImageSocialSignupLoginButton'

const SignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SignupButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xxsm);
  justify-content: center;

  ${media.sm`
    justify-content: unset;
  `}
`

const EmailSignupLink = styled(Link)`
  margin: var(--spacing-xsm) 0;
  color: var(--color-typography-medium);
  font-size: 14px;
  line-height: 25px;

  &:hover {
    color: var(--color-primary);
  }
`

const PageSectionTextImageSocialSignupLogin = () => {
  const { locale } = useIntl()

  const googleSignupLink = useGoogleSignUpLink(locale)
  const linkedInSignupLink = useLinkedInSignUpLink(locale)
  const emailSignupLink = useSignUpLink(locale, true)

  return (
    <SignupWrapper>
      <SignupButtonWrapper>
        <PageSectionTextImageSocialSignupLoginButton to={googleSignupLink}>
          <GoogleIcon
            width={20}
            height={20}
          />
          {googleSignupLink.text.value}
        </PageSectionTextImageSocialSignupLoginButton>
        <PageSectionTextImageSocialSignupLoginButton
          $linkedIn
          to={linkedInSignupLink}>
          <LinkedInIcon
            width={20}
            height={20}
            style={{ fill: 'white' }}
          />
          {linkedInSignupLink.text.value}
        </PageSectionTextImageSocialSignupLoginButton>
      </SignupButtonWrapper>
      <EmailSignupLink
        to={emailSignupLink}
        rel="nofollow">
        {emailSignupLink.text.value}
      </EmailSignupLink>
    </SignupWrapper>
  )
}

export default PageSectionTextImageSocialSignupLogin
