import styled from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import CTA from './CTA'
import PageSectionTextImageSocialSignupLogin from './PageSectionTextImageSocialSignupLogin'

const CtaButtonsContainer = styled.div`
  display: ${({ $textSide }) => ($textSide ? 'none' : 'block')};
  text-align: center;
  padding: 20px;

  @media screen and (min-width: ${legacyBreakpoints.mobile}) {
    display: ${({ $textSide }) => ($textSide ? 'block' : 'none')};
    text-align: inherit;
    padding: unset;
  }
`

const TextImageCTA = styled(CTA)`
  padding: 20px 35px;
  font-size: 16px;

  &:first-of-type {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 20px;

    @media screen and (max-width: ${legacyBreakpoints.mobile}) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`
const SubText = styled.p`
  font-size: 12px !important;
  margin: 0;
  padding: 0;
`

const CtaButtons = ({ textSide, showLogin, primaryCta, subText, isBanner }) => (
  <CtaButtonsContainer $textSide={textSide}>
    {showLogin && <PageSectionTextImageSocialSignupLogin />}
    {!showLogin && primaryCta && (
      <TextImageCTA
        $primaryHighlight={isBanner}
        $primary={!isBanner}
        to={primaryCta}>
        {primaryCta.text.value}
      </TextImageCTA>
    )}
    {subText && <SubText>{subText}</SubText>}
  </CtaButtonsContainer>
)

export default CtaButtons
