import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { heading1Style, heading2Style } from '../utils/FontAndSpacingStyles'
import media, { legacyBreakpoints } from '../utils/media'
import CtaButtons from './PageSectionTextImageCtaButtons'
import Video from './Video'
import Wrapper from './Wrapper'

const Content = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;

  h1,
  p,
  div {
    width: 100%;
  }

  p,
  li {
    color: var(--color-typography-medium) !important;
  }

  ul {
    font-size: 16px;
    list-style: none;
    margin-left: 21px;
  }

  li::before {
    color: var(--color-primary);
    content: '•';
    display: inline-block;
    margin-left: -1em;
    width: 1em;
  }

  ${({ $isBanner }) =>
    $isBanner &&
    css`
      p:first-of-type {
        font-size: 20px;
        line-height: 30px;
      }
    `}

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    align-items: center;
    text-align: center;
    width: 100%;

    ${({ $isBanner }) =>
      $isBanner &&
      css`
        p:first-of-type {
          font-size: 18px;
          line-height: 26px;
        }
      `}
  }
`

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  height: calc(75vw - 30px);
  mix-blend-mode: ${({ $isVideo }) => ($isVideo ? 'multiply' : 'inherit')};

  ${media.md`
    padding: 0;
    width: 50%;
    height: calc(50vw - 32px);
    max-height: 551px;
  `}
`

const TextImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md);

  ${({ $alignment }) =>
    $alignment === 'Right'
      ? css`
          flex-direction: row-reverse;

          ${Content} {
            padding-left: 60px;

            @media screen and (max-width: ${legacyBreakpoints.mobile}) {
              padding-left: 20px;
            }
          }
        `
      : css`
          flex-direction: row;
        `}

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    flex-direction: column;
    ${({ $isBanner }) =>
      $isBanner
        ? css`
            margin-bottom: 0 !important;
            padding: 20px;
          `
        : css`
            margin-bottom: 50px;
          `}
  }
`

const bannerTitleStyles = css`
  ${heading1Style}
  margin: 0 0 1.15rem 0;
`

const PrimaryTitle = styled.h1`
  &:not(h1) {
    ${heading2Style}
    margin: 0 0 var(--spacing-md);

    ${({ $isBanner }) => $isBanner && bannerTitleStyles}
  }
`

const SecondaryTitle = styled.h1`
  font-family: 'InterVariable', sans-serif;
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 8px;

  && {
    color: var(--color-typography-medium);
  }
`

const PageSectionTextImage = ({
  title,
  subtitle,
  description,
  showLogin,
  primaryCta,
  subText,
  isBanner,
  image,
  alignment,
  imageAlt,
}) => {
  if (!title || !image) {
    return null
  }

  const isVideo = image.localFile.extension === 'mp4'

  return (
    <Wrapper>
      <TextImageContainer
        $alignment={alignment}
        $isBanner={isBanner}>
        {title && (
          <Content $isBanner={isBanner}>
            {subtitle && <SecondaryTitle as={isBanner ? 'h1' : 'h2'}>{subtitle}</SecondaryTitle>}
            <PrimaryTitle
              $isBanner={isBanner}
              as={isBanner ? (subtitle ? 'h2' : 'h1') : subtitle ? 'h3' : 'h2'}>
              {title}
            </PrimaryTitle>
            {description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            )}
            <CtaButtons
              textSide
              showLogin={showLogin}
              primaryCta={primaryCta}
              subText={subText}
              isBanner={isBanner}
            />
          </Content>
        )}
        <MediaWrapper $isVideo={isVideo}>
          {isVideo ? (
            <Video url={image.localFile.publicURL} />
          ) : (
            <GatsbyImage
              image={getImage(image.localFile)}
              loading={isBanner ? 'eager' : 'lazy'}
              alt={imageAlt ?? ''}
            />
          )}
        </MediaWrapper>
        <CtaButtons
          showLogin={showLogin}
          primaryCta={primaryCta}
          subText={subText}
          isBanner={isBanner}
        />
      </TextImageContainer>
    </Wrapper>
  )
}

export default PageSectionTextImage
